// /**
//  * Theme Customizer enhancements for a better user experience.
//  *
//  * Contains handlers to make Theme Customizer preview reload changes asynchronously.
//  */

// ( function( $ ) {
// 	// Site title and description.
// 	wp.customize( 'blogname', function( value ) {
// 		value.bind( function( to ) {
// 			$( '.site-title a' ).text( to );
// 		} );
// 	} );
// 	wp.customize( 'blogdescription', function( value ) {
// 		value.bind( function( to ) {
// 			$( '.site-description' ).text( to );
// 		} );
// 	} );
// 	// Header text color.
// 	wp.customize( 'header_textcolor', function( value ) {
// 		value.bind( function( to ) {
// 			if ( 'blank' === to ) {
// 				$( '.site-title, .site-description' ).css( {
// 					'clip': 'rect(1px, 1px, 1px, 1px)',
// 					'position': 'absolute'
// 				} );
// 			} else {
// 				$( '.site-title, .site-description' ).css( {
// 					'clip': 'auto',
// 					'color': to,
// 					'position': 'relative'
// 				} );
// 			}
// 		} );
// 	} );
// } )( jQuery );
;(function($) {

	WW1 = (function() {
    return {

      mobile_menu_toggle : function() {
      	$('.menu-toggle').click(function(e) {

          e.preventDefault();

          var site_navigation = '#site-navigation';

          $(site_navigation).toggleClass('toggled');
          if ($(site_navigation).hasClass('toggled')) {
            $(site_navigation).slideDown(500);
          } else {
            $(site_navigation).slideUp(500);
          }
        });

      },
      stories_carousel : function() {
          $('.stories-carousel').slick({
              infinite: true,
              centerMode: true,
              slidesToShow: 3,
              arrows: true,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1
                  }
                }
              ]

          });
      },
      roh_list : function() {

		  var paginationTopOptions = {
			  paginationClass: 'roh-pagination--top'
		  };

		  var paginationBottomOptions = {
			  paginationClass: 'roh-pagination--bottom'
		  };

          var options = {
			  valueNames: [ 'name', 'dod', 'unit', 'rank' ],
			  page: 50,
			  plugins: [
				  ListPagination(paginationTopOptions),
				  ListPagination(paginationBottomOptions)
			  ]
		  };
          var userList = new List('roh-list', options);
          var search = '.search';
          var noItems = '.no-items-message';
          var tryAgain = '.try-again';


          userList.sort('name', { order: "asc" });

          $(search).keyup(function() {

            var filterObject = [$('.search-filter option:selected').val()];

            if (filterObject == "all" ) {
              // If option = all then continue
            } else {

              userList.search(this.value, filterObject);
              // If option = !all then apply search filter
            }

            if (userList.visibleItems.length < 1) {
              // If there are no results then show warning and try again button
              $(noItems).show();
              $(tryAgain).show();

              $(tryAgain).click(function() {
                // Reset Search and Focus on search field
                userList.search();
                $(search).val('').focus();
                $(noItems).hide();
              });

            } else {

              $(noItems).hide();

            }

          });

		  // Scroll to the top of the list after pagination has been clicked.
		  $('.roh-pagination').click(function() {
		      $('html, body').animate({
		          scrollTop: $('#roh-list').offset().top -50
		      }, 200);
		  });


      },
      secondary_menu_toggle : function() {
        if ($('body.page-id-10', 'body.page-id-5' ).length){

        } else {
          if ($(window).width() > 1023) {

            var child_pages =  '.child-pages';

            var uni_and_war =  '.university-and-war';
            var uni_and_war_nav = '#menu-university-and-war-menu';

            var events =  '.centenary-events';
            var events_nav = '#menu-events-menu';

            $(uni_and_war).mouseover(function() {
                $(child_pages).removeClass('is--active');
                $(uni_and_war_nav).addClass('is--active');

            });

            $(events).mouseover(function() {

                $(child_pages).removeClass('is--active');
                $(events_nav).addClass('is--active');

            });

            $(child_pages).mouseleave(function() {
                $(this).removeClass('is--active');
            });

          } else {

          }
        }
      }
    };

	})();

  $(document).ready(function(jQuery) {
     $('.none-link').click(function(e) {
       e.preventDefault();
    });
    WW1.mobile_menu_toggle();
    WW1.secondary_menu_toggle();

    if ($().slick) {
      WW1.stories_carousel();
    }

    if ($('#roh-list').length) {
      WW1.roh_list();
    }

  });

  $(window).resize(function(jQuery) {
    WW1.secondary_menu_toggle();
  });

})(jQuery);
;// ( function() {
// 	var is_webkit = navigator.userAgent.toLowerCase().indexOf( 'webkit' ) > -1,
// 	    is_opera  = navigator.userAgent.toLowerCase().indexOf( 'opera' )  > -1,
// 	    is_ie     = navigator.userAgent.toLowerCase().indexOf( 'msie' )   > -1;

// 	if ( ( is_webkit || is_opera || is_ie ) && document.getElementById && window.addEventListener ) {
// 		window.addEventListener( 'hashchange', function() {
// 			var element = document.getElementById( location.hash.substring( 1 ) );

// 			if ( element ) {
// 				if ( ! /^(?:a|select|input|button|textarea)$/i.test( element.tagName ) )
// 					element.tabIndex = -1;

// 				element.focus();
// 			}
// 		}, false );
// 	}
// })();
